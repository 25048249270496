import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldBirthDate: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { isDesktop, styles } = useStyles();
  const { values, touched, errors, setFieldValue, validateField } =
    useFormikContext<SignUpData>();

  const field = (
    params:
      | StandardTextFieldProps
      | FilledTextFieldProps
      | OutlinedTextFieldProps
  ) => (
    <TextField
      {...params}
      id="birthDate"
      name="birthDate"
      style={{ ...styles.input, ...styles.thirdWidth }}
      error={touched.birthDate && Boolean(errors.birthDate)}
      helperText={touched.birthDate && errors.birthDate}
      onBlur={() => {
        if (values.birthDate)
          firebaseLog({
            action: "Field: birthDate",
            message: values.birthDate.toString(),
            type: "log",
          });
      }}
    />
  );

  const handleChange = (date: Date | null) => {
    setFieldValue("birthDate", moment(date).toDate());
    validateField("birthDate");
  };

  return (
    <div style={styles.thirdWidth}>
      {isDesktop && (
        <DesktopDatePicker
          label="Data de nascimento*"
          inputFormat="DD/MM/YYYY"
          value={values.birthDate}
          onChange={handleChange}
          renderInput={(params) => field(params)}
        />
      )}
      {!isDesktop && (
        <MobileDatePicker
          label="Data de nascimento*"
          inputFormat="DD/MM/YYYY"
          value={values.birthDate}
          onChange={handleChange}
          renderInput={(params) => field(params)}
        />
      )}
    </div>
  );
};

export default FieldBirthDate;
