import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useAuth, useFirestore } from "reactfire";
import { LogData } from "../types/logData";

export default () => {
  const firestore = useFirestore();
  const auth = useAuth();

  const firebaseLog = async (data: LogData) => {
    const logsCollection = collection(firestore, "logs");
    await addDoc(logsCollection, {
      ...data,
      userId: auth.currentUser?.uid || "not signed in",
      timestamp: serverTimestamp(),
    });
  };

  return { firebaseLog };
};
