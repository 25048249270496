import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";

import { SignUpData } from "../types/signUp";
import useSaveData from "./useSaveData";
import useFirebaseLog from "./useFirebaseLog";

export default () => {
  const { saveData } = useSaveData();
  const navigate = useNavigate();
  const { firebaseLog } = useFirebaseLog();

  const onSubmit = async (
    values: SignUpData,
    { setSubmitting }: FormikHelpers<SignUpData>
  ) => {
    firebaseLog({ action: "signUpClick", message: "", type: "log" });

    const result = await saveData({ ...values });

    setSubmitting(false);

    if (result) {
      navigate("/confirmation");
    } else {
      navigate("/error");
    }
  };

  return { onSubmit };
};
