import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";
import useCampaigns from "../../../hooks/useCampaigns";

type FieldCampaignProps = {
  mode: string;
};

const FieldCampaign: FC<FieldCampaignProps> = ({ mode }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();
  const { campaigns } = useCampaigns();

  const title =
    mode === "school"
      ? "Como você ficou sabendo sobre o nosso colégio?"
      : "Como você ficou sabendo sobre o UniDrummond?";

  return (
    <FormControl error={Boolean(errors.campaign)} fullWidth={!isDesktop}>
      <InputLabel id="demo-simple-select-helper-label">{title}*</InputLabel>
      <Select
        fullWidth
        id="campaign"
        name="campaign"
        label={`${title}*`}
        value={values.campaign}
        onChange={handleChange}
        error={Boolean(errors.campaign)}
        style={{
          ...styles.input,
          ...styles.twoThirdsWidth,
          marginBottom: !errors.campaign ? 20 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: campaign",
            message: values.campaign || "",
            type: "log",
          });
          handleBlur(e);
        }}
      >
        {campaigns.map((campaign) => (
          <MenuItem key={campaign.id} value={campaign.name}>
            {campaign.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText style={{ marginBottom: errors.campaign ? 20 : 0 }}>
        {errors.campaign}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldCampaign;
