import { useFormikContext } from "formik";
import React, { FC } from "react";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";
import RecaptchaButton from "../components/RecaptchaButton";

import FieldAddress from "../fields/FieldAddress";
import FieldAgree from "../fields/FieldAgree";
import FieldBirthDate from "../fields/FieldBirthDate";
import FieldCity from "../fields/FieldCity";
import FieldComplement from "../fields/FieldComplement";
import FieldCPF from "../fields/FieldCPF";
import FieldDistrict from "../fields/FieldDistrict";
import FieldEmail from "../fields/FieldEmail";
import FieldName from "../fields/FieldName";
import FieldPhone from "../fields/FieldPhone";
import FieldSex from "../fields/FieldSex";
import FieldState from "../fields/FieldState";
import FieldZip from "../fields/FieldZip";
import FieldCampaign from "../fields/FieldCampaign";

const CollegeForm: FC = () => {
  const { isSubmitting, submitForm } = useFormikContext<SignUpData>();
  const { styles } = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        flexWrap: "wrap",
      }}
    >
      <FieldName />
      <FieldEmail />
      <FieldPhone />
      <FieldBirthDate />
      <FieldSex />
      <FieldCPF />
      <div style={styles.twoThirdsWidth} />
      <FieldZip />
      <FieldAddress />
      <FieldComplement />
      <FieldCity />
      <FieldDistrict />
      <FieldState />
      <FieldCampaign mode="college" />
      <FieldAgree />
      <RecaptchaButton isSubmitting={isSubmitting} submitForm={submitForm} />
    </div>
  );
};

export default CollegeForm;
