import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldDistrict: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <TextField
      fullWidth
      id="district"
      name="district"
      label="Bairro*"
      value={values.district}
      onChange={handleChange}
      error={touched.district && Boolean(errors.district)}
      helperText={touched.district && errors.district}
      style={{ ...styles.input, ...styles.thirdWidth }}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: district",
          message: values.district,
          type: "log",
        });
        handleBlur(e);
      }}
    />
  );
};

export default FieldDistrict;
