import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { COLORS } from "../../../../constants/colors";
import useStyles from "../../../../hooks/useStyles";
import { EventDate } from "../../../../types/eventDate";
import { MenuItem, Select } from "@mui/material";
import { useFormikContext } from "formik";
import { SignUpData } from "../../../../types/signUp";

type EventProps = {
  eventDate?: EventDate;
  eventDates?: EventDate[];
};

const Event: FC<EventProps> = ({ eventDates }) => {
  const { isDesktop } = useStyles();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "college";
  const { setFieldValue } = useFormikContext<SignUpData>();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (mode === "school" && eventDates && eventDates?.length == 2) {
      setFieldValue("event", eventDates[0]);
    }
  }, [eventDates]);

  return (
    <div
      style={{
        fontSize: 22,
        color: COLORS.secondary,
        alignSelf: "center",
        textAlign: "center",
      }}
    >
      <b>
        {mode === "college" && "Vestibular Social"}
        {/* {mode === "college" &&
          `${eventDate?.text} ${
            eventDate
              ? " - " +
                moment(eventDate.timestamp).format("DD/MM/YY [às] HH:mm[hs]")
              : ""
          }`} */}
        {mode === "post" && "Pós-graduação"}
        {mode === "ead-post" && "Pós-graduação EAD"}
        {mode === "ead-college" && "Graduação EAD"}
        {mode === "ead-eja" && "EJA EAD"}
        {mode === "school" &&
          eventDates &&
          eventDates?.length == 0 &&
          "Desafio Cultural"}
        {mode === "school" && eventDates && eventDates?.length == 1 && (
          <div>
            {eventDates[0].text} {isDesktop ? "- " : <br />}
            {moment(eventDates[0].timestamp).format("DD/MM/YY [às] HH[hs]")}
          </div>
        )}
        {mode === "school" && eventDates && eventDates?.length == 2 && (
          <Select
            fullWidth
            value={index}
            onChange={(value) => {
              setFieldValue("event", eventDates[value.target.value as number]);
              setIndex(value.target.value as number);
            }}
          >
            <MenuItem value={0}>
              {eventDates[0].text} -{" "}
              {moment(eventDates[0].timestamp).format("DD/MM/YY [às] HH[hs]")}
            </MenuItem>
            <MenuItem value={1}>
              {eventDates[1].text} -{" "}
              {moment(eventDates[1].timestamp).format("DD/MM/YY [às] HH[hs]")}
            </MenuItem>
          </Select>
        )}
      </b>
    </div>
  );
};

export default Event;
