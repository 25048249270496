import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { collection, query, where, orderBy } from "firebase/firestore";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import useCourses from "../../../hooks/useCourses";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";

const FieldStudentCampus: FC<FieldProps> = ({ index = 0 }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleBlur, setFieldValue } =
    useFormikContext<SignUpData>();
  const { getCampus, getShift } = useCourses();

  const firestore = useFirestore();
  const courseCampusCollectionRef = collection(firestore, "coursesCampus");
  const courseCampusQuery = query(
    courseCampusCollectionRef,
    where("courseID", "==", values.studentCourses[index]),
    where("active", "==", true),
    orderBy("campusID")
  );
  const { data: availableCourses = [] } = useFirestoreCollectionData(
    courseCampusQuery,
    {
      idField: "id",
    }
  );

  const errorCheck = (errors.studentCourseCampusIDs &&
    Boolean(errors.studentCourseCampusIDs[index])) as boolean;
  const errorMessage = (errors.studentCourseCampusIDs &&
    errors.studentCourseCampusIDs[index]) as string;

  return (
    <FormControl error={errorCheck} fullWidth={!isDesktop}>
      <InputLabel id="demo-simple-select-helper-label">
        Período e unidade*
      </InputLabel>
      <Select
        fullWidth
        id={`studentCourseCampusIDs[${index}]`}
        name={`studentCourseCampusIDs[${index}]`}
        label="Período e unidade*"
        value={values.studentCourseCampusIDs[index]}
        onChange={(e) => {
          setFieldValue(`studentCourseCampusIDs[${index}]`, e.target.value);
          setFieldValue(
            `studentCourseCampuses[${index}]`,
            availableCourses.find((course) => course.id === e.target.value)
          );
        }}
        error={errorCheck}
        style={{
          ...styles.input,
          ...styles.thirdWidth,
          marginBottom: !errorMessage ? 24 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: courseCampus",
            message: values.studentCourseCampusIDs[index],
            type: "log",
          });
          handleBlur(e);
        }}
      >
        {availableCourses &&
          availableCourses.map((course) => (
            <MenuItem key={course.id} value={course.id}>
              {getCampus(course.campusID)?.name}{" "}
              {course.campusID !== "ead" && "-"} {getShift(course.shift)?.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText style={{ marginBottom: errorCheck ? 20 : 0 }}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldStudentCampus;
