import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

const ConfirmRedaction: FC<Props> = ({ handleClose, handleConfirm, open }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Finalizar a redação</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Após finalizar a redação ela será enviada e não será possível
          alterá-la. Tem certeza que deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={handleConfirm} autoFocus>
          Sim, quero enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRedaction;
