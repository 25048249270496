import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";

const FieldStudentBirthDate: FC<FieldProps> = ({ index = 0 }) => {
  const { firebaseLog } = useFirebaseLog();
  const { isDesktop, styles } = useStyles();
  const { values, touched, errors, handleBlur, setFieldValue, validateField } =
    useFormikContext<SignUpData>();

  const errorCheck = (errors.studentBirthDates &&
    touched.studentBirthDates &&
    typeof touched.studentBirthDates !== "boolean" &&
    touched.studentBirthDates[index] &&
    Boolean(errors.studentBirthDates[index])) as boolean;
  const errorMessage = (errors.studentBirthDates &&
    touched.studentBirthDates &&
    typeof touched.studentBirthDates !== "boolean" &&
    touched.studentBirthDates[index] &&
    errors.studentBirthDates[index]) as string;

  const field = (
    params:
      | StandardTextFieldProps
      | FilledTextFieldProps
      | OutlinedTextFieldProps
  ) => (
    <TextField
      {...params}
      id={`studentBirthDates[${index}]`}
      name={`studentBirthDates[${index}]`}
      error={errorCheck}
      helperText={errorMessage}
      style={{ ...styles.input, ...styles.thirdWidth }}
      onBlur={(e) => {
        handleBlur(e);
        if (values.birthDate)
          firebaseLog({
            action: "Field: birthDate",
            message: values.studentBirthDates[index].toString() || "",
            type: "log",
          });
      }}
    />
  );

  const handleChange = (value: Date | null) => {
    setFieldValue(`studentBirthDates[${index}]`, moment(value).toDate());
    validateField(`studentBirthDates[${index}]`);
  };

  return (
    <div style={styles.thirdWidth}>
      {isDesktop && (
        <DesktopDatePicker
          label="Data de nascimento*"
          inputFormat="DD/MM/YYYY"
          value={values.studentBirthDates[index]}
          onChange={handleChange}
          renderInput={(params) => field(params)}
        />
      )}
      {!isDesktop && (
        <MobileDatePicker
          label="Data de nascimento*"
          inputFormat="DD/MM/YYYY"
          value={values.studentBirthDates[index]}
          onChange={handleChange}
          renderInput={(params) => field(params)}
        />
      )}
    </div>
  );
};

export default FieldStudentBirthDate;
