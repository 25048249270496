import { collection, limit, orderBy, query, where } from "firebase/firestore";
import React, { FC, useEffect, useState } from "react";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";

import { COLORS } from "../../../../constants/colors";
import { SignUpData } from "../../../../types/signUp";

const PreviousSignUps: FC = () => {
  const auth = useAuth();
  const [signUp, setSignUp] = useState<SignUpData | null>(null);
  const firestore = useFirestore();
  const signups = collection(firestore, "signUps");
  const signUpsQuery = query(
    signups,
    where("userId", "==", auth.currentUser?.uid),
    limit(1),
    orderBy("timestamp", "desc")
  );
  const { data: signUpsCollection = [] } = useFirestoreCollectionData(
    signUpsQuery,
    {
      idField: "id",
    }
  );

  useEffect(() => {
    if (signUpsCollection.length > 0)
      setSignUp(signUpsCollection[0] as SignUpData);
  }, [signUpsCollection]);

  if (signUpsCollection.length === 0 || !signUp) return null;

  return (
    <div style={{ alignSelf: "center" }}>
      <br />
      Detectamos que você já fez uma inscrição neste dispositivo para{" "}
      <span style={{ color: COLORS.secondary }}>{signUp.name}</span>.
      <br />
      <br />
    </div>
  );
};

export default PreviousSignUps;
