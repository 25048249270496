import { createSlice } from "@reduxjs/toolkit";
import { Campus, Shift, CourseType, Series } from "../types/courseCampus";
import { DeviceInfo } from "../types/deviceInfo";
import { SignUpLocalData } from "../types/signUp";

export type SignUpState = {
  signUp?: SignUpLocalData;
  campuses?: Campus[];
  shifts?: Shift[];
  courseType?: CourseType[];
  series?: Series[];
  deviceInfo?: DeviceInfo;
};

const initialState: SignUpState = {
  signUp: undefined,
  campuses: [],
  shifts: [],
  courseType: [],
  series: [],
  deviceInfo: undefined,
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState: initialState,
  reducers: {
    saveCurrent: (state, action) => {
      state.signUp = action.payload;
    },
    setCampuses: (state, action) => {
      state.campuses = action.payload;
    },
    setShifts: (state, action) => {
      state.shifts = action.payload;
    },
    setCourseType: (state, action) => {
      state.courseType = action.payload;
    },
    setSeries: (state, action) => {
      state.series = action.payload;
    },
    setDeviceInfo: (state, action) => {
      state.deviceInfo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveCurrent,
  setCampuses,
  setCourseType,
  setSeries,
  setShifts,
  setDeviceInfo,
} = signUpSlice.actions;

export default signUpSlice.reducer;
