import { Button } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import useStyles from "../../../../hooks/useStyles";

const RecaptchaButton: FC<{
  isSubmitting: boolean;
  submitForm: () => void;
}> = ({ isSubmitting, submitForm }) => {
  const { styles } = useStyles();
  const [token, setToken] = useState<string | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const __DEV__ = process.env.NODE_ENV === "development";

  const handleReCaptchaVerify = useCallback(
    async (submit: boolean) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("signUp");
      setToken(token);

      if (submit && (__DEV__ || token)) submitForm();
    },
    [executeRecaptcha]
  );

  useEffect(() => {
    handleReCaptchaVerify(false);
  }, [handleReCaptchaVerify]);

  return (
    <Button
      disabled={__DEV__ ? isSubmitting : isSubmitting || !token}
      variant="contained"
      style={{ ...styles.button, ...styles.fullWidth }}
      onClick={() => handleReCaptchaVerify(true)}
      color="secondary"
    >
      Concluir
    </Button>
  );
};

export default RecaptchaButton;
