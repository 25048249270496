import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { COLORS } from "./colors";

export const theme = createTheme({
  palette: {
    primary: {
      light: COLORS.primaryLight,
      main: COLORS.primary,
      dark: COLORS.primaryDark,
      contrastText: COLORS.white,
    },
    secondary: {
      light: COLORS.secondaryLight,
      main: COLORS.secondary,
      dark: COLORS.secondaryDark,
      contrastText: COLORS.black,
    },
    error: {
      light: COLORS.errorLight,
      main: COLORS.error,
      dark: COLORS.errorDark,
      contrastText: COLORS.white,
    },
  },
  typography: {
    fontFamily: "Lucida Sans Unicode, Lucida Grande, sans-serif",
  },
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
      },
    },
  },
});
