import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldSex: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <FormControl error={Boolean(errors.sex)} fullWidth={!isDesktop}>
      <InputLabel id="demo-simple-select-helper-label">Sexo*</InputLabel>
      <Select
        fullWidth
        id="sex"
        name="sex"
        label="Sexo*"
        value={values.sex}
        onChange={handleChange}
        error={Boolean(errors.sex)}
        style={{
          ...styles.input,
          ...styles.thirdWidth,
          marginBottom: !errors.sex ? 20 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: sex",
            message: values.sex,
            type: "log",
          });
          handleBlur(e);
        }}
      >
        <MenuItem value={"Masculino"}>Masculino</MenuItem>
        <MenuItem value={"Feminino"}>Feminino</MenuItem>
      </Select>
      <FormHelperText style={{ marginBottom: errors.sex ? 20 : 0 }}>
        {errors.sex}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldSex;
