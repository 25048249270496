import React, { FC } from "react";
import useAddPageView from "../../hooks/useAddPageView";

const SignUpError: FC = () => {
  useAddPageView();

  return (
    <div>
      Não foi possível realizar sua inscrição. Verifique se possui internet e
      tente novamente
    </div>
  );
};

export default SignUpError;
