import moment from "moment";
import { SignUpData, SignUpErrors } from "../types/signUp";

function isValidCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  const partscpf = cpf.split("").map((el) => +el);
  const rest = (count: number) =>
    ((partscpf
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;
  return rest(10).toString() === cpf[9] && rest(11).toString() === cpf[10];
}

export const validate = (values: SignUpData) => {
  const errors: SignUpErrors = {};

  if (values.mode !== "school" && !values.courseCampusID)
    errors.courseCampusID = "Campo obrigatório";

  if (values.mode !== "school" && !values.course)
    errors.course = "Campo obrigatório";

  if (!values.email) errors.email = "Required";
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
    errors.email = "E-mail inválido";

  if (!values.name || values.name.length < 5) errors.name = "Nome inválido";

  if (!values.cpf || !isValidCPF(values.cpf)) errors.cpf = "CPF inválido";

  const phone = values.phone.replace(/\D/g, "");
  if (!phone || phone.length < 11) errors.phone = "Telefone inválido";

  if (
    values.mode !== "school" &&
    (!values.birthDate ||
      !moment(values.birthDate).isBefore(moment().subtract(1, "day")))
  )
    errors.birthDate = "Data de nascimento inválida";

  if (values.mode !== "school" && !values.sex) errors.sex = "Campo obrigatório";

  const zip = values.zip.replace(/\D/g, "");
  if (!zip || zip.length < 8) errors.zip = "CEP inválido";

  if (!values.address || values.address.length < 5)
    errors.address = "Endereço inválido";

  if (!values.city || values.city.length < 3) errors.city = "Cidade inválida";

  if (!values.district || values.district.length < 3)
    errors.district = "Bairro inválido";

  if (!values.state) errors.state = "Escolha um estado";

  if (!values.agree) errors.agree = "Você precisa aceitar o regulamento";

  if (
    values.mode === "college" &&
    values.isOnline &&
    values.redaction &&
    (values.redaction.split(" ").length < 100 ||
      values.redaction.split(" ").length > 250)
  )
    errors.redaction = "Número de palavras inválido";

  if (values.mode === "college" && values.isOnline === undefined)
    errors.isOnline = "Campo obrigatório";

  if (values.mode === "school") {
    errors.studentBirthDates = [];
    values.studentBirthDates.map((birthDate) => {
      if (birthDate && moment(birthDate).isBefore(moment().subtract(1, "day")))
        errors.studentBirthDates?.push("");
      else errors.studentBirthDates?.push("Data de nascimento inválida");
    });
    if (!errors.studentBirthDates.some((error) => error !== ""))
      delete errors.studentBirthDates;

    errors.studentCourseCampusIDs = [];
    values.studentCourseCampusIDs.map((course) => {
      if (course) errors.studentCourseCampusIDs?.push("");
      else errors.studentCourseCampusIDs?.push("Campo obrigatório");
    });
    if (!errors.studentCourseCampusIDs.some((error) => error !== ""))
      delete errors.studentCourseCampusIDs;

    errors.studentCourses = [];
    values.studentCourses.map((course) => {
      if (course) errors.studentCourses?.push("");
      else errors.studentCourses?.push("Campo obrigatório");
    });
    if (!errors.studentCourses.some((error) => error !== ""))
      delete errors.studentCourses;

    errors.studentNames = [];
    values.studentNames.map((name) => {
      if (name && name.length >= 5) errors.studentNames?.push("");
      else errors.studentNames?.push("Nome inválido");
    });
    if (!errors.studentNames.some((error) => error !== ""))
      delete errors.studentNames;

    errors.studentSexes = [];
    values.studentSexes.map((sex) => {
      if (sex && sex.length >= 5) errors.studentSexes?.push("");
      else errors.studentSexes?.push("Escolha uma opção");
    });
    if (!errors.studentSexes.some((error) => error !== ""))
      delete errors.studentSexes;
  }

  // console.log(errors);

  return errors;
};
