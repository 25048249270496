import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";

const FieldStudentSex: FC<FieldProps> = ({ index = 0 }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, errors, handleBlur, setFieldValue, setFieldTouched } =
    useFormikContext<SignUpData>();

  const errorCheck =
    (errors.studentSexes &&
      errors.studentSexes.length > 0 &&
      Boolean(errors.studentSexes[index])) ||
    false;

  const errorMessage =
    errors.studentSexes &&
    errors.studentSexes.length > 0 &&
    errors.studentSexes[index];

  return (
    <FormControl error={errorCheck} fullWidth>
      <InputLabel id="demo-simple-select-helper-label">Sexo*</InputLabel>
      <Select
        fullWidth
        id={`studentSexes[${index}]`}
        name={`studentSexes[${index}]`}
        label="Sexo*"
        value={values.studentSexes[index]}
        onChange={(value) => {
          setFieldValue(`studentSexes[${index}]`, value.target.value);
          setFieldTouched(`studentSexes[${index}]`);
        }}
        error={errorCheck}
        style={{
          ...styles.input,
          ...styles.thirdWidth,
          marginBottom: !errorCheck ? 20 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: student sex",
            message: values.studentSexes[index],
            type: "log",
          });
          handleBlur(e);
        }}
      >
        <MenuItem value={"Masculino"}>Masculino</MenuItem>
        <MenuItem value={"Feminino"}>Feminino</MenuItem>
      </Select>
      <FormHelperText style={{ marginBottom: errorCheck ? 20 : 0 }}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldStudentSex;
