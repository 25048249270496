import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { EventDate } from "../../../types/eventDate";
import { SignUpData } from "../../../types/signUp";

type Props = {
  eventDate?: EventDate;
};

const FieldIsOnline: FC<Props> = ({ eventDate }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleBlur, setFieldValue } =
    useFormikContext<SignUpData>();

  return (
    <FormControl error={Boolean(errors.isOnline)} fullWidth={!isDesktop}>
      <InputLabel id="isOnline">Escolha*</InputLabel>
      <Select
        fullWidth
        id="isOnline"
        name="isOnline"
        label="Escolha*"
        value={
          values.isOnline === true
            ? "online"
            : values.isOnline === false
            ? "presential"
            : ""
        }
        onChange={(e) => {
          setFieldValue("isOnline", e.target.value === "online");
        }}
        error={Boolean(errors.isOnline)}
        style={{
          ...styles.input,
          ...styles.twoThirdsWidth,
          marginBottom: !errors.isOnline ? 20 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: isOnline",
            message: values.isOnline?.toString() || "not applicable",
            type: "log",
          });
          handleBlur(e);
        }}
      >
        {eventDate && (
          <MenuItem value={"presential"}>
            {moment(eventDate.timestamp).format("DD/MM/YY [às] HH:mm[hs]")} -
            Presencial
          </MenuItem>
        )}
        <MenuItem value={"online"}>Online</MenuItem>
      </Select>
      <FormHelperText style={{ marginBottom: errors.isOnline ? 20 : 0 }}>
        {errors.isOnline}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldIsOnline;
