import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import InputMask from "react-input-mask";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldCPF: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <InputMask
      mask="999.999.999-99"
      onChange={handleChange}
      value={values.cpf}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: cpf",
          message: values.cpf,
          type: "log",
        });
        handleBlur(e);
      }}
    >
      <TextField
        fullWidth
        id="cpf"
        name="cpf"
        label="CPF*"
        type="phone"
        error={touched.cpf && Boolean(errors.cpf)}
        helperText={touched.cpf && errors.cpf}
        style={{ ...styles.input, ...styles.thirdWidth }}
      />
    </InputMask>
  );
};

export default FieldCPF;
