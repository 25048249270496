import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { ChangeEvent, FC } from "react";
import InputMask from "react-input-mask";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldZip: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = useFormikContext<SignUpData>();

  const onChange = async (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChange(value);

    const zip = value.target.value.replace(/\D/g, "");

    if (zip.length === 8) {
      const response = await fetch(`https://viacep.com.br/ws/${zip}/json/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const result = await response.json();
      if (result.erro) {
        return;
      }

      setFieldValue("city", result.localidade);
      setFieldValue("state", result.uf);
      setFieldValue("address", result.logradouro);
      setFieldValue("district", result.bairro);
      setFieldTouched("city", false);
      setFieldTouched("state", false);
      setFieldTouched("address", false);
      setFieldTouched("district", false);
      validateField("city");
      validateField("state");
      validateField("address");
      validateField("district");
    }
  };

  return (
    <InputMask
      mask="99999-999"
      onChange={onChange}
      value={values.zip}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: zip",
          message: values.zip,
          type: "log",
        });
        handleBlur(e);
      }}
    >
      <TextField
        fullWidth
        id="zip"
        name="zip"
        label="CEP*"
        error={touched.zip && Boolean(errors.zip)}
        helperText={touched.zip && errors.zip}
        style={{ ...styles.input, ...styles.thirdWidth }}
      />
    </InputMask>
  );
};

export default FieldZip;
