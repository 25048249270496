import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CSSProperties } from "react";
import { COLORS } from "../constants/colors";

export default () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const styles = {
    button: {
      border: "none",
      borderRadius: "4px",
      padding: "8px 16px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "bold",
    } as CSSProperties,
    buttonContainer: {
      display: "flex",
      flexDirection: isDesktop ? "row" : "column",
      justifyContent: isDesktop ? "center" : "space-between",
      flex: 1,
      width: "100%",
    } as CSSProperties,
    blueContainer: {
      backgroundColor: COLORS.primary,
      width: "100%",
      padding: "4px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    } as CSSProperties,
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "16px",
      justifyContent: "flex-start",
      maxWidth: "820px",
      backgroundColor: COLORS.white,
      borderRadius: "8px",
      margin: "16px",
    } as CSSProperties,
    confirmationContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "16px",
      justifyContent: "flex-start",
      maxWidth: "820px",
      backgroundColor: COLORS.secondary,
      borderRadius: "8px",
      margin: "16px",
      color: COLORS.white,
    } as CSSProperties,
    form: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
    } as CSSProperties,
    fullWidth: {
      width: "100%",
    } as CSSProperties,
    textarea: {
      width: isDesktop ? "820px" : "100%",
    } as CSSProperties,
    input: {
      width: isDesktop ? "400px" : "100%",
      marginBottom: "24px",
    } as CSSProperties,
    screen: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    } as CSSProperties,
    screenLoading: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100%",
    } as CSSProperties,
    thirdWidth: {
      width: isDesktop ? "260px" : "100%",
    } as CSSProperties,
    twoThirdsWidth: {
      width: isDesktop ? "540px" : "100%",
    } as CSSProperties,
  };

  return { styles, isDesktop };
};
