export const COLORS = {
  primary: "#183d6f",
  primaryDark: "#0e2545",
  primaryLight: "#2358a0",
  secondary: "#ee7a30",
  secondaryDark: "#eb640e",
  secondaryLight: "#ea945d",
  success: "#28a745",
  error: "#dc3545",
  errorDark: "#9F2431",
  errorLight: "#EF6572",
  warning: "#ffc107",
  black: "#000000",
  white: "#ffffff",
};
