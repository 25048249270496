import { FC } from "react";
import React, { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "reactfire";

type Props = {
  children: JSX.Element;
};

const ProtectedRoute: FC<Props> = ({ children }) => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  if (!auth.currentUser) {
    const search: string[] = [];
    searchParams.forEach((value, key) => search.push(`${key}=${value}`));
    return <Navigate to={`/?${search.join("&")}`} />;
  }
  return children;
};

export default ProtectedRoute;
