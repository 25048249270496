import { Button, Menu, MenuItem } from "@mui/material";
import React, { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useStyles from "../../../../hooks/useStyles";
import { styles } from "./TopMenu.styles";

const TopMenu: FC = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "college";
  const { isDesktop } = useStyles();

  const [anchorEl, setAnchorEl] = useState<(HTMLElement | null)[]>([
    null,
    null,
    null,
  ]);

  const open = (id: number) => Boolean(anchorEl[id]);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    if (anchorEl[id] !== event.currentTarget) updateMenu(event, id);
  };

  const handleClose = (id: number) => updateMenu(null, id);

  const updateMenu = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    id: number
  ) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[id] = event ? event.currentTarget : null;
    anchorEl.map((el, index) => {
      if (event && index !== id) newAnchorEl[index] = null;
    });
    setAnchorEl(newAnchorEl);
  };
  console.log("mode", mode);

  const logo = () => {
    if (mode.includes("uniten"))
      return (
        <img
          src="https://firebasestorage.googleapis.com/v0/b/inscricoes-drummond.appspot.com/o/logo_uniten.png?alt=media&token=f7435e08-ab80-4122-ade4-c143697ae100"
          alt="logo"
          width={100}
          style={isDesktop ? styles.logo : styles.logoMobile}
          onClick={() =>
            window.open("https://unitenvestibular.com.br/", "_self")
          }
        />
      );
    if (mode.includes("unitec"))
      return (
        <img
          src="https://firebasestorage.googleapis.com/v0/b/inscricoes-drummond.appspot.com/o/logo_unitec.png?alt=media&token=efa070c2-c0d8-4864-972a-7bd56b8afedf"
          alt="logo"
          width={100}
          style={isDesktop ? styles.logo : styles.logoMobile}
          onClick={() =>
            window.open("https://unitecvestibular.com.br/", "_self")
          }
        />
      );

    return (
      <img
        src="https://drummond.com.br/wp-content/uploads/2020/06/logo-drumond-azul.svg"
        alt="logo"
        width={100}
        style={isDesktop ? styles.logo : styles.logoMobile}
        onClick={() => window.open("https://drummond.com.br/", "_self")}
      />
    );
  };

  if (mode === "college")
    return (
      <div>
        <div style={styles.bar} />
        <div
          style={
            isDesktop
              ? styles.container
              : { ...styles.container, ...styles.containerMobile }
          }
        >
          {logo()}
          <Button
            onClick={() =>
              window.open(
                "https://drummond.com.br/graduacao-presencial/",
                "_self"
              )
            }
          >
            Nossos {!isDesktop && <br />}
            CURSOS
          </Button>
          <Button onClick={(e) => handleClick(e, 2)}>
            Formas de {!isDesktop && <br />}
            INGRESSO
          </Button>
          <Menu
            anchorEl={anchorEl[2]}
            open={open(2)}
            onClose={() => handleClose(2)}
            MenuListProps={{ onClick: () => handleClose(2) }}
            onMouseLeave={() => handleClose(2)}
          >
            <MenuItem
              onClick={() =>
                window.open(
                  "https://drummond.com.br/inscricoes-drummond/",
                  "_self"
                )
              }
            >
              Vestibular Social
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open("https://drummond.com.br/enem/", "_self")
              }
            >
              ENEM
            </MenuItem>
          </Menu>
          <Button onClick={(e) => handleClick(e, 3)}>
            Bolsas e {!isDesktop && <br />}
            DESCONTOS
          </Button>
          <Menu
            anchorEl={anchorEl[3]}
            open={open(3)}
            onClose={() => handleClose(3)}
            MenuListProps={{ onClick: () => handleClose(3) }}
            onMouseLeave={() => handleClose(3)}
            style={{ width: 200, height: 200 }}
          >
            <MenuItem
              onClick={() =>
                window.open(
                  "https://drummond.com.br/inscricoes-drummond/",
                  "_self"
                )
              }
            >
              Vestibular Social
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open("https://drummond.com.br/prouni/", "_self")
              }
            >
              PROUNI
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open("https://drummond.com.br/fies/", "_self")
              }
            >
              FIES
            </MenuItem>
          </Menu>
        </div>
      </div>
    );

  if (mode === "school")
    return (
      <div>
        <div style={styles.bar} />
        <div style={styles.container}>
          {logo()}
          <Button
            onClick={() =>
              window.open("https://drummond.com.br/colegios/", "_self")
            }
          >
            Nossos COLÉGIOS
          </Button>
        </div>
      </div>
    );

  if (mode === "post")
    return (
      <div>
        <div style={styles.bar} />
        <div style={styles.container}>
          {logo()}
          <Button
            onClick={() =>
              window.open(
                "https://drummond.com.br/pos-graduacao-presencial/",
                "_self"
              )
            }
          >
            Nossos CURSOS
          </Button>
        </div>
      </div>
    );

  if (mode === "ead-post")
    return (
      <div>
        <div style={styles.bar} />
        <div style={styles.container}>
          {logo()}
          <Button
            onClick={() =>
              window.open("https://drummond.com.br/ead-pos-graduacao/", "_self")
            }
          >
            Nossos CURSOS
          </Button>
        </div>
      </div>
    );

  if (mode === "ead-eja")
    return (
      <div>
        <div style={styles.bar} />
        <div style={styles.container}>
          {logo()}
          <Button
            onClick={() =>
              window.open(
                "https://drummond.com.br/eja-educacao-jovens-e-adultos/",
                "_self"
              )
            }
          >
            O CURSO
          </Button>
        </div>
      </div>
    );

  if (mode === "ead-college")
    return (
      <div>
        <div style={styles.bar} />
        <div style={styles.container}>
          {logo()}
          <Button
            onClick={() =>
              window.open(
                "https://drummond.com.br/ensino-a-distancia-ead-graduacao/",
                "_self"
              )
            }
          >
            Nossos CURSOS
          </Button>
        </div>
      </div>
    );

  if (mode.includes("uniten") || mode.includes("unitec"))
    return (
      <div>
        <div style={styles.bar} />
        <div style={styles.container}>{logo()}</div>
      </div>
    );

  return (
    <div>
      <div style={styles.bar} />
      <div style={styles.container}>
        <img
          src="https://drummond.com.br/wp-content/uploads/2020/06/logo-drumond-azul.svg"
          alt="logo"
          width={80}
          style={isDesktop ? styles.logo : styles.logoMobile}
          onClick={() => window.open("https://drummond.com.br/", "_self")}
        />
      </div>
    </div>
  );
};

export default TopMenu;
