import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getFirestore } from "firebase/firestore";
import React from "react";
import {
  FirestoreProvider,
  useFirebaseApp,
  AuthProvider,
  AppCheckProvider,
  FunctionsProvider,
} from "reactfire";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { theme } from "./constants/theme";
import BasicForm from "./screens/BasicForm";
import Confirmation from "./screens/Confirmation";
import SignUpError from "./screens/SignUpError";

import "./App.css";
import "./index.css";
import store from "./store/store";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import Splash from "./screens/Splash";
import Protected from "./screens/Protected";
import { getFunctions } from "firebase/functions";
import Redaction from "./screens/Redaction";
import RedactionConfirmation from "./screens/RedactionConfirmation";

const router = createBrowserRouter([
  {
    path: "/form",
    element: (
      <Protected>
        <BasicForm />
      </Protected>
    ),
  },
  {
    path: "/redaction",
    element: (
      <Protected>
        <Redaction />
      </Protected>
    ),
  },
  {
    path: "/redaction-confirmation",
    element: (
      <Protected>
        <RedactionConfirmation />
      </Protected>
    ),
  },
  {
    path: "/confirmation",
    element: (
      <Protected>
        <Confirmation />
      </Protected>
    ),
  },
  {
    path: "/error",
    element: (
      <Protected>
        <SignUpError />
      </Protected>
    ),
  },
  {
    path: "*",
    element: <Splash />,
  },
]);

const APP_CHECK_TOKEN = "6LcLlFIjAAAAAOt7qV7bIZShgIAbjqh_WaJJNvNN";

const App = () => {
  const __DEV__ = process.env.NODE_ENV === "development";
  const firestoreInstance = getFirestore(useFirebaseApp());
  const authInstance = getAuth(useFirebaseApp());
  const functionsInstance = getFunctions(useFirebaseApp());
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      __DEV__ ? "9AC338DB-E9F9-48CE-8A38-1A005AFC6FDD" : APP_CHECK_TOKEN
    ),
    isTokenAutoRefreshEnabled: true,
  });

  return (
    <AppCheckProvider sdk={appCheck}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FunctionsProvider sdk={functionsInstance}>
              <AuthProvider sdk={authInstance}>
                <FirestoreProvider sdk={firestoreInstance}>
                  <RouterProvider router={router} />
                </FirestoreProvider>
              </AuthProvider>
            </FunctionsProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </AppCheckProvider>
  );
};

export default App;
