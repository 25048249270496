import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldEmail: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <TextField
      fullWidth
      id="email"
      name="email"
      label="E-mail*"
      value={values.email}
      onChange={handleChange}
      error={touched.email && Boolean(errors.email)}
      helperText={touched.email && errors.email}
      style={styles.input}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: email",
          message: values.email,
          type: "log",
        });
        handleBlur(e);
      }}
    />
  );
};

export default FieldEmail;
