import { collection, getDocs } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import { CampaignData } from "../types/signUp";

export default () => {
  const firestore = useFirestore();

  const [campaigns, setCampaigns] = useState<CampaignData[]>([]);

  useEffect(() => {
    loadCampaigns();
  }, []);

  const loadCampaigns = useCallback(async () => {
    const campaignsRef = collection(firestore, "campaigns");
    const campaignsDoc = await getDocs(campaignsRef);
    const campaignsData: CampaignData[] = [];
    campaignsDoc.forEach((doc) => {
      campaignsData.push({
        id: doc.id,
        name: doc.data().name,
      });
    });
    setCampaigns(campaignsData);
  }, []);

  return { campaigns };
};
