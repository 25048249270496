import React, { FC } from "react";

import { COLORS } from "../../../../constants/colors";
import useStyles from "../../../../hooks/useStyles";

const SignUpNow: FC = () => {
  const { isDesktop } = useStyles();

  return (
    <div
      style={{
        fontSize: isDesktop ? 72 : 32,
        margin: 24,
        fontWeight: "bold",
        color: COLORS.white,
        WebkitTextStrokeColor: COLORS.primary,
        WebkitTextStrokeWidth: isDesktop ? 2 : 1,
        textAlign: "center",
      }}
    >
      INSCREVA-SE AGORA
    </div>
  );
};

export default SignUpNow;
