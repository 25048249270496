import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useStyles from "../../../hooks/useStyles";
import { CourseCampus } from "../../../types/courseCampus";
import { SignUpData } from "../../../types/signUp";

import { addStudent, removeStudent } from "../BasicForm.helpers";
import RecaptchaButton from "../components/RecaptchaButton";
import FieldAddress from "../fields/FieldAddress";
import FieldAgree from "../fields/FieldAgree";
import FieldCity from "../fields/FieldCity";
import FieldComplement from "../fields/FieldComplement";
import FieldCPF from "../fields/FieldCPF";
import FieldDistrict from "../fields/FieldDistrict";
import FieldEmail from "../fields/FieldEmail";
import FieldName from "../fields/FieldName";
import FieldPhone from "../fields/FieldPhone";
import FieldState from "../fields/FieldState";
import FieldStudentBirthDate from "../fields/FieldStudentBirthDate";
import FieldStudentCampus from "../fields/FieldStudentCampus";
import FieldStudentCourse from "../fields/FieldStudentCourse";
import FieldStudentName from "../fields/FieldStudentName";
import FieldStudentSex from "../fields/FieldStudentSex";
import FieldZip from "../fields/FieldZip";
import FieldCampaign from "../fields/FieldCampaign";

const SchoolForm: FC<{ courses: CourseCampus[] }> = ({ courses }) => {
  const { styles } = useStyles();
  const formik = useFormikContext<SignUpData>();
  const values = formik.values;
  const isSubmitting = formik.isSubmitting;
  const submitForm = formik.submitForm;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        flexWrap: "wrap",
      }}
    >
      <FieldName />
      <FieldEmail />
      <FieldPhone />
      <FieldCPF />
      <div style={styles.thirdWidth} />
      <FieldZip />
      <FieldAddress />
      <FieldComplement />
      <FieldCity />
      <FieldDistrict />
      <FieldState />
      <FieldCampaign mode="college" />
      {values.studentNames.map((name, index) => (
        <div key={index}>
          <h3
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Dados do aluno #{index + 1}
            {index > 0 && (
              <Button
                color="secondary"
                onClick={() => removeStudent(index, formik)}
              >
                Remover aluno
              </Button>
            )}
          </h3>
          <div style={styles.form}>
            <FieldStudentCourse courses={courses} index={index} />
            <FieldStudentCampus index={index} />
            <FieldStudentName index={index} />
            <FieldStudentBirthDate index={index} />
            <FieldStudentSex index={index} />
          </div>
        </div>
      ))}
      <FieldAgree />
      <div style={styles.thirdWidth} />
      <div style={styles.buttonContainer}>
        <Button
          variant="contained"
          disabled={isSubmitting || values.studentNames.length >= 5}
          style={{ ...styles.button, ...styles.fullWidth }}
          onClick={() => addStudent(formik)}
        >
          Adicionar aluno
        </Button>
        <div style={{ width: "20px", height: "20px" }} />
        <RecaptchaButton isSubmitting={isSubmitting} submitForm={submitForm} />
      </div>
    </div>
  );
};

export default SchoolForm;
