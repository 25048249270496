import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import InputMask from "react-input-mask";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldPhone: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <InputMask
      mask="(99) 99999-9999"
      onChange={handleChange}
      value={values.phone}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: phone",
          message: values.phone,
          type: "log",
        });
        handleBlur(e);
      }}
    >
      <TextField
        fullWidth
        id="phone"
        name="phone"
        label="Telefone*"
        type="phone"
        error={touched.phone && Boolean(errors.phone)}
        helperText={touched.phone && errors.phone}
        style={{ ...styles.input, ...styles.thirdWidth }}
      />
    </InputMask>
  );
};

export default FieldPhone;
