import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { collection, query, where, orderBy } from "firebase/firestore";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import useCourses from "../../../hooks/useCourses";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";
import { useSearchParams } from "react-router-dom";

const FieldCampus: FC<FieldProps> = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<SignUpData>();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "college";

  const { getCampus, getShift } = useCourses();

  const firestore = useFirestore();
  const courseCampusCollectionRef = collection(firestore, "coursesCampus");
  const courseCampusQuery = query(
    courseCampusCollectionRef,
    where("courseID", "==", values.course),
    where("mode", "==", mode),
    where("active", "==", true),
    orderBy("type"),
    orderBy("campusID")
  );
  const { data: availableCourses = [] } = useFirestoreCollectionData(
    courseCampusQuery,
    {
      idField: "id",
    }
  );

  return (
    <FormControl error={Boolean(errors.courseCampusID)} fullWidth={!isDesktop}>
      <InputLabel id="demo-simple-select-helper-label">
        Período e unidade*
      </InputLabel>
      <Select
        fullWidth
        id="courseCampusID"
        name="courseCampusID"
        label="Período e unidade*"
        value={values.courseCampusID}
        onChange={(e) => {
          handleChange(e);
          setFieldValue(
            "courseCampus",
            availableCourses.find((course) => course.id === e.target.value)
          );
        }}
        error={Boolean(errors.courseCampusID)}
        style={{
          ...styles.input,
          ...styles.thirdWidth,
          marginBottom: !errors.courseCampusID ? 24 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: courseCampus",
            message: values.courseCampusID,
            type: "log",
          });
          handleBlur(e);
        }}
      >
        {availableCourses &&
          availableCourses.map((course) => (
            <MenuItem key={course.id} value={course.id}>
              {getCampus(course.campusID)?.name}{" "}
              {course.campusID !== "ead" && "-"} {getShift(course.shift)?.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText style={{ marginBottom: errors.courseCampusID ? 20 : 0 }}>
        {errors.courseCampusID}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldCampus;
