import { httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFunctions } from "reactfire";

export default () => {
  const functions = useFunctions();
  const navigate = useNavigate();
  const signUpExists = httpsCallable(functions, "signUpExists");
  const saveRedaction = httpsCallable(functions, "saveRedaction");
  const [searchParams] = useSearchParams();
  const signUpID = searchParams.get("signUp");
  const [valid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [text, setText] = useState("");

  const loadSignUp = useCallback(async () => {
    const exists = await signUpExists({ id: signUpID });

    setValid(exists.data as boolean);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadSignUp();
  }, []);

  const finish = (text?: string) => {
    setShowDialog(true);
    setText(text || "");
  };

  const send = async () => {
    setIsSaving(true);
    close();
    const result = await saveRedaction({
      id: signUpID,
      text,
    });

    if (result.data) {
      navigate("/redaction-confirmation");
    } else {
      setIsSaving(false);
      setShowErrorDialog(true);
    }
  };

  const close = () => setShowDialog(false);

  const closeError = () => setShowErrorDialog(false);

  return {
    close,
    closeError,
    finish,
    isLoading,
    isSaving,
    send,
    showDialog,
    showErrorDialog,
    valid,
  };
};
