import React, { FC } from "react";
import { COLORS } from "../../constants/colors";
import useAddPageView from "../../hooks/useAddPageView";

import useStyles from "../../hooks/useStyles";
import TopMenu from "../BasicForm/components/TopMenu";

const RedactionConfirmation: FC = () => {
  const __DEV__ = process.env.NODE_ENV === "development";
  useAddPageView(!__DEV__);

  const { styles } = useStyles();

  return (
    <div>
      <TopMenu />
      <div style={styles.screen}>
        <div style={styles.blueContainer}>
          <div
            style={{ ...styles.confirmationContainer, alignItems: "center" }}
          >
            <b>
              Obrigado! Redação enviada com sucesso! Agora é só aguardar o
              resultado.
            </b>
          </div>
          <div style={{ fontSize: 10, color: COLORS.white }}>
            <br />
            V1.2.13
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedactionConfirmation;
