import React, { FC } from "react";

import "./style.css";

type Props = {
  embedId: string;
};

const YoutubeEmbed: FC<Props> = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="480"
      height="320"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default YoutubeEmbed;
