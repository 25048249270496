import { doc, getDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import { Banners } from "../types/banner";

export default (mode: string) => {
  const firestore = useFirestore();

  const [banners, setBanners] = useState<Banners | undefined>(undefined);

  useEffect(() => {
    loadBanners();
  }, []);

  const loadBanners = useCallback(async () => {
    const bannersRef = doc(firestore, `banners/${mode}`);
    const bannersDoc = await getDoc(bannersRef);
    if (bannersDoc.exists()) {
      const bannersData: Banners = {
        desktop: bannersDoc.data().desktop,
        mobile: bannersDoc.data().mobile,
      };
      setBanners(bannersData);
    }
  }, []);

  return { banners };
};
