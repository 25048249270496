import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldRedaction: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, setFieldValue, handleBlur } =
    useFormikContext<SignUpData>();
  const preventChange = (e: React.ClipboardEvent<HTMLDivElement>) =>
    e.preventDefault();

  return (
    <TextField
      fullWidth
      id="redaction"
      name="redaction"
      label={`Redação (${
        values.redaction?.trim().split(" ").length || 0
      }/250 palavras)`}
      rows={15}
      multiline
      value={values.redaction}
      onChange={(e) => {
        setFieldValue("redaction", e.target.value.replace("  ", " "));
      }}
      error={touched.redaction && Boolean(errors.redaction)}
      helperText={touched.redaction && errors.redaction}
      style={styles.textarea}
      onCut={preventChange}
      onCopy={preventChange}
      onPaste={preventChange}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: redaction",
          message: values.redaction || "",
          type: "log",
        });
        handleBlur(e);
      }}
    />
  );
};

export default FieldRedaction;
