import { CSSProperties } from "react";
import { COLORS } from "../../../../constants/colors";

export const styles = {
  bar: {
    display: "flex",
    height: 24,
    backgroundColor: COLORS.primary,
  } as CSSProperties,
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 8,
  } as CSSProperties,
  containerMobile: {
    justifyContent: "space-around",
  } as CSSProperties,
  logo: {
    marginRight: 32,
  } as CSSProperties,
  logoMobile: {
    marginRight: "40%",
    marginLeft: "40%",
    marginBottom: 12,
  } as CSSProperties,
};
