import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";

const FieldStudentCourse: FC<FieldProps> = ({ courses, index = 0 }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleBlur, setFieldValue } =
    useFormikContext<SignUpData>();

  const errorCheck = (errors.studentCourses &&
    Boolean(errors.studentCourses[index])) as boolean;
  const errorMessage = (errors.studentCourses &&
    errors.studentCourses[index]) as string;

  return (
    <FormControl error={errorCheck} fullWidth={!isDesktop}>
      <InputLabel id="course-label">Curso*</InputLabel>
      <Select
        fullWidth
        id={`studentCourses[${index}]`}
        name={`studentCourses[${index}]`}
        label="Curso*"
        value={courses ? values.studentCourses[index] : ""}
        onChange={(e) => {
          setFieldValue(`studentCourses[${index}]`, e.target.value);
          setFieldValue(`studentCourseCampusIDs[${index}]`, "");
        }}
        error={errorCheck}
        style={{
          ...styles.input,
          ...styles.twoThirdsWidth,
          marginBottom: !errorMessage ? 24 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: course",
            message: values.studentCourses[index],
            type: "log",
          });
          handleBlur(e);
        }}
      >
        {courses &&
          courses.map((course) => (
            <MenuItem key={course.id} value={course.id}>
              {course.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText style={{ marginBottom: errorCheck ? 20 : 0 }}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldStudentCourse;
