import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";

const FieldCourse: FC<FieldProps> = ({ courses }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<SignUpData>();

  return (
    <FormControl error={Boolean(errors.course)} fullWidth={!isDesktop}>
      <InputLabel id="course-label">Curso*</InputLabel>
      <Select
        fullWidth
        id="course"
        name="course"
        label="Curso*"
        value={courses ? values.course : ""}
        onChange={(e) => {
          handleChange(e);
          setFieldValue("courseCampusID", "");
        }}
        error={Boolean(errors.course)}
        style={{
          ...styles.input,
          ...styles.twoThirdsWidth,
          marginBottom: !errors.course ? 24 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: course",
            message: values.course,
            type: "log",
          });
          handleBlur(e);
        }}
      >
        {courses &&
          courses.map((course) => (
            <MenuItem key={course.id} value={course.id}>
              {course.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText style={{ marginBottom: errors.course ? 20 : 0 }}>
        {errors.course}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldCourse;
