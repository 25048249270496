import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FirebaseAppProvider } from "reactfire";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-T9JRNRX",
};

TagManager.initialize(tagManagerArgs);

const firebaseConfig = {
  apiKey: "AIzaSyB0niF2s48xOXm5N-gi4aAwee3avgnps3E",
  authDomain: "inscricoes-drummond.firebaseapp.com",
  projectId: "inscricoes-drummond",
  storageBucket: "inscricoes-drummond.appspot.com",
  messagingSenderId: "101926575365",
  appId: "1:101926575365:web:9b5a3584c8fe108964ab6c",
  measurementId: "G-NP2NG8RZTE",
};

const __DEV__ = process.env.NODE_ENV === "development";

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={
      __DEV__
        ? "6Ldqf9UjAAAAAOoXJWVksbllf4zln4jAf3p2-skP"
        : "6LcLlFIjAAAAAOt7qV7bIZShgIAbjqh_WaJJNvNN"
    }
    language="pt-BR"
  >
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </GoogleReCaptchaProvider>
);

reportWebVitals();
