import { CircularProgress } from "@mui/material";
import { signInAnonymously, User } from "firebase/auth";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "reactfire";

import useStyles from "../../hooks/useStyles";

const Splash: FC = () => {
  const auth = useAuth();
  const { styles } = useStyles();
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    signIn();
  }, []);

  const signIn = async () => {
    const res = await signInAnonymously(auth);
    setUser(res.user);
  };

  useEffect(() => {
    const search: string[] = [];
    searchParams.forEach((value, key) => search.push(`${key}=${value}`));

    const redactionID = searchParams.get("signUp") || undefined;

    if (user && redactionID)
      return navigate(`/redaction?signUp=${redactionID}`);

    if (user) navigate(`/form?${search.join("&")}`);
  }, [user]);

  return (
    <div style={styles.screenLoading}>
      <CircularProgress />
    </div>
  );
};

export default Splash;
