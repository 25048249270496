import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldName: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <TextField
      fullWidth
      id="name"
      name="name"
      label="Nome completo*"
      value={values.name}
      onChange={handleChange}
      error={touched.name && Boolean(errors.name)}
      helperText={touched.name && errors.name}
      style={styles.input}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: name",
          message: values.name,
          type: "log",
        });
        handleBlur(e);
      }}
    />
  );
};

export default FieldName;
