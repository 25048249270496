import { Button, CircularProgress } from "@mui/material";
import { FormikProps, Formik } from "formik";
import React, { FC, useRef } from "react";
import { COLORS } from "../../constants/colors";
import { validate } from "../../helpers/validate";
import useAddPageView from "../../hooks/useAddPageView";
import useInitialValues from "../../hooks/useInitialValues";
import useOnSubmit from "../../hooks/useOnSubmit";

import useStyles from "../../hooks/useStyles";
import { SignUpData } from "../../types/signUp";
import TopMenu from "../BasicForm/components/TopMenu";
import FieldRedaction from "../BasicForm/fields/FieldRedaction";
import ConfirmRedaction from "./components/ConfirmRedaction";
import SendError from "./components/SendError";
import useSignUp from "./useSignUp";

const Redaction: FC = () => {
  const __DEV__ = process.env.NODE_ENV === "development";
  useAddPageView(!__DEV__);

  const { styles } = useStyles();
  const { onSubmit } = useOnSubmit();
  const initialValues = useInitialValues();
  const {
    close,
    closeError,
    finish,
    isLoading,
    isSaving,
    send,
    showDialog,
    showErrorDialog,
    valid,
  } = useSignUp();

  const formik = useRef<FormikProps<SignUpData>>(null);

  if (isLoading)
    return (
      <div>
        <TopMenu />
        <div style={styles.screen}>
          <div style={styles.blueContainer}>
            <div style={{ ...styles.container, alignItems: "center" }}>
              <br />
              <CircularProgress />
              <br />
              Aguarde, validando sua inscrição...
              <br />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <TopMenu />
      <div style={styles.screen}>
        <div style={styles.blueContainer}>
          {valid ? (
            <div style={{ ...styles.container, alignItems: "center" }}>
              {__DEV__ && (
                <div style={{ fontSize: 22, color: COLORS.error }}>
                  <b>DEV MODE</b>
                </div>
              )}
              <div style={{ fontSize: 22 }}>
                <b>Redação</b>
              </div>
              <br />
              <div>
                Você deve fazer uma redação entre 100 e 250 palavras sobre o
                tema:
              </div>
              <br />
              <div>
                <b>Aluno protagonista</b>
              </div>
              <br />
              <br />
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                validateOnBlur={true}
                innerRef={formik}
              >
                {({ errors, handleSubmit, values }) => (
                  <form style={styles.form} onSubmit={handleSubmit}>
                    <FieldRedaction />
                    <Button
                      disabled={
                        values.redaction === "" ||
                        !!errors.redaction ||
                        isSaving
                      }
                      variant="contained"
                      style={{
                        ...styles.button,
                        ...styles.fullWidth,
                        marginTop: 16,
                      }}
                      onClick={() => finish(values.redaction)}
                      color="secondary"
                    >
                      {isSaving
                        ? "Enviando, por favor, aguarde..."
                        : "Concluir"}
                    </Button>
                  </form>
                )}
              </Formik>
              <ConfirmRedaction
                open={showDialog}
                handleConfirm={send}
                handleClose={close}
              />
              <SendError open={showErrorDialog} handleClose={closeError} />
            </div>
          ) : (
            <div style={{ ...styles.container, alignItems: "center" }}>
              Inscrição inválida, verifique se o link está correto.
            </div>
          )}
          <div style={{ fontSize: 10, color: COLORS.white }}>
            <br />
            V1.2.13
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redaction;
