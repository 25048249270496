import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldState: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();

  return (
    <FormControl error={Boolean(errors.state)} fullWidth={!isDesktop}>
      <InputLabel id="demo-simple-select-helper-label">Estado*</InputLabel>
      <Select
        fullWidth
        id="state"
        name="state"
        label="Estado*"
        value={values.state}
        onChange={handleChange}
        error={Boolean(errors.state)}
        style={{
          ...styles.input,
          ...styles.thirdWidth,
          marginBottom: !errors.state ? 20 : 0,
        }}
        onBlur={(e) => {
          firebaseLog({
            action: "Field: state",
            message: values.state,
            type: "log",
          });
          handleBlur(e);
        }}
      >
        <MenuItem value={"AC"}>AC</MenuItem>
        <MenuItem value={"AL"}>AL</MenuItem>
        <MenuItem value={"AP"}>AP</MenuItem>
        <MenuItem value={"AM"}>AM</MenuItem>
        <MenuItem value={"BA"}>BA</MenuItem>
        <MenuItem value={"CE"}>CE</MenuItem>
        <MenuItem value={"DF"}>DF</MenuItem>
        <MenuItem value={"ES"}>ES</MenuItem>
        <MenuItem value={"GO"}>GO</MenuItem>
        <MenuItem value={"MA"}>MA</MenuItem>
        <MenuItem value={"MT"}>MT</MenuItem>
        <MenuItem value={"MS"}>MS</MenuItem>
        <MenuItem value={"MG"}>MG</MenuItem>
        <MenuItem value={"PA"}>PA</MenuItem>
        <MenuItem value={"PB"}>PB</MenuItem>
        <MenuItem value={"PR"}>PR</MenuItem>
        <MenuItem value={"PE"}>PE</MenuItem>
        <MenuItem value={"PI"}>PI</MenuItem>
        <MenuItem value={"RJ"}>RJ</MenuItem>
        <MenuItem value={"RN"}>RN</MenuItem>
        <MenuItem value={"RS"}>RS</MenuItem>
        <MenuItem value={"RO"}>RO</MenuItem>
        <MenuItem value={"RR"}>RR</MenuItem>
        <MenuItem value={"SC"}>SC</MenuItem>
        <MenuItem value={"SP"}>SP</MenuItem>
        <MenuItem value={"SE"}>SE</MenuItem>
        <MenuItem value={"TO"}>TO</MenuItem>
      </Select>
      <FormHelperText style={{ marginBottom: 20 }}>
        {errors.state}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldState;
