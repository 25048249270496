import axios from "axios";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect } from "react";
import { useAuth, useFirestore } from "reactfire";
import {
  browserName,
  isMobile,
  fullBrowserVersion,
  isMacOs,
  isAndroid,
  isIOS,
  isTablet,
  isWindows,
} from "react-device-detect";

import { useSearchParams } from "react-router-dom";

export default (addView = true) => {
  const firestore = useFirestore();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const params: { key: string; value: string | number | Date }[] = [];
  searchParams.forEach((value, key) => {
    if (key && value) params.push({ key, value });
  });
  const mode = searchParams.get("mode") || "college";

  const addPageView = useCallback(async () => {
    const pageViewsCollection = collection(firestore, "pageViews");
    const pageView = {
      browser: {
        name: browserName,
        version: fullBrowserVersion,
      },
      deviceProps: {
        isAndroid,
        isIOS,
        isMacOs,
        isMobile,
        isTablet,
        isWindows,
      },
      route: {
        name: window.location.pathname,
        params,
      },
      mode,
    };

    const pageViewAdded = await addDoc(pageViewsCollection, {
      ...pageView,
      userId: auth.currentUser?.uid || "not signed in",
      timestamp: serverTimestamp(),
    });
    const pageViewDoc = doc(pageViewsCollection, pageViewAdded.id);

    navigator.geolocation.getCurrentPosition(async (position) => {
      if (position) {
        await updateDoc(pageViewDoc, {
          position: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          userId: auth.currentUser?.uid || "not signed in",
        });
      }
    });

    const res = await axios.get(
      "https://geolocation-db.com/json/67273a00-5c4b-11ed-9204-d161c2da74ce"
    );

    if (res.status === 200) {
      await updateDoc(pageViewDoc, {
        ip: res.data.IPv4,
        ipLocation: {
          city: res.data.city,
          country: res.data.country_name,
          state: res.data.state,
          postal: res.data.postal,
          code: res.data.country_code,
          position: {
            lat: res.data.latitude,
            lon: res.data.longitude,
          },
        },
        userId: auth.currentUser?.uid || "not signed in",
      });
    }
  }, []);

  useEffect(() => {
    if (addView) addPageView();
  }, [addPageView]);
};
