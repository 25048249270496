import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { FieldProps } from "../../../types/fieldProps";
import { SignUpData } from "../../../types/signUp";

const FieldStudentName: FC<FieldProps> = ({ index = 0 }) => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<SignUpData>();

  const errorCheck =
    (errors.studentNames &&
      errors.studentNames.length > 0 &&
      Boolean(errors.studentNames[index]) &&
      touched.studentNames &&
      typeof touched.studentNames !== "boolean" &&
      touched.studentNames[index]) ||
    false;
  const errorMessage =
    errors.studentNames &&
    errors.studentNames.length > 0 &&
    touched.studentNames &&
    typeof touched.studentNames !== "boolean" &&
    touched.studentNames[index] &&
    errors.studentNames[index];

  return (
    <TextField
      fullWidth
      id={`studentNames[${index}]`}
      name={`studentNames[${index}]`}
      label="Nome completo*"
      value={values.studentNames[index]}
      onChange={(value) => {
        setFieldValue(`studentNames[${index}]`, value.target.value);
        setFieldTouched(`studentNames[${index}]`, true);
      }}
      error={errorCheck}
      helperText={errorMessage}
      style={{ ...styles.input, ...styles.twoThirdsWidth }}
      onBlur={(e) => {
        firebaseLog({
          action: "Field: student name",
          message: values.studentNames[index],
          type: "log",
        });
        handleBlur(e);
      }}
    />
  );
};

export default FieldStudentName;
