import { FormikProps } from "formik";
import { SignUpData } from "../../types/signUp";

export const removeStudent = (
  index: number,
  formik: FormikProps<SignUpData>
) => {
  const studentNames = formik.values.studentNames;
  studentNames.splice(index, 1);
  formik.setFieldValue("studentNames", studentNames);

  const studentCourseCampusIDs = formik.values.studentCourseCampusIDs;
  studentCourseCampusIDs.splice(index, 1);
  formik.setFieldValue("studentCourseCampusIDs", studentCourseCampusIDs);

  const studentCourses = formik.values.studentCourses;
  studentCourses.splice(index, 1);
  formik.setFieldValue("studentCourses", studentCourses);

  const studentSexes = formik.values.studentSexes;
  studentSexes.splice(index, 1);
  formik.setFieldValue("studentSexes", studentSexes);

  const studentBirthDates = formik.values.studentBirthDates;
  studentBirthDates.splice(index, 1);
  formik.setFieldValue("studentBirthDates", studentBirthDates);
};

export const addStudent = (formik: FormikProps<SignUpData>) => {
  formik.setFieldValue("studentNames", formik.values.studentNames.concat(""));
  formik.setFieldValue(
    "studentCourseCampusIDs",
    formik.values.studentCourseCampusIDs.concat("")
  );
  formik.setFieldValue(
    "studentCourses",
    formik.values.studentCourses.concat("")
  );
  formik.setFieldValue("studentSexes", formik.values.studentSexes.concat(""));
  formik.setFieldValue(
    "studentBirthDates",
    formik.values.studentBirthDates.concat(new Date())
  );
};
